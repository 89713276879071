<template>
  <main>
    <div class="horiz-container">
      <h1 class="page-title">Политика в отношении обработки персональных данных</h1>
      <p><b>1. Общие положения</b></p>
      <p>Настоящая Политика обработки персональных данных определяет политику Общества с ограниченной ответственностью «ИВЕНТ ХАБ» (далее Компания или Оператор) в отношении обработки и защиты персональных данных о субъектах персональных данных, которая может быть получена Компанией при использовании субъектом персональных данных услуг, неисключительной лицензии, предоставляемых в том числе посредством сайта, сервисов, служб, программ, используемых Компанией.</p>
      <p>Субъектом персональных данных является физическое лицо (далее Пользователь), которое самостоятельно предоставило персональные данные о себе и при использовании сайта, регистрации и создании Личного кабинета, для приобретения или реализации услуг и сервисов посредством сайта в сети Интернет по адресу: <a href="https://mentoring.digital/">https://mentoring.digital/</a>.</p>
      <p>Настоящая Политика разработана во исполнение требований п. 2 ч. 1 ст. 18.1 Федерального закона от 27.07.2006 N 152-ФЗ «О персональных данных» (далее Закон о персональных данных).</p>
      <p><b>2. Основные понятия, используемые в Политике</b></p>
      <p>2.1. Автоматизированная обработка персональных данных – обработка персональных данных с помощью средств вычислительной техники;</p>
      <p>2.2. Блокирование персональных данных – временное прекращение обработки персональных данных (за исключением случаев, если обработка необходима для уточнения персональных данных);</p>
      <p>2.3. Веб-сайт – совокупность графических и информационных материалов, а также программ для ЭВМ и баз данных, обеспечивающих их доступность в сети интернет по сетевому адресу https://mentoring.digital/ ;</p>
      <p>2.4. Информационная система персональных данных — совокупность содержащихся в базах данных персональных данных, и обеспечивающих их обработку информационных технологий и технических средств;</p>
      <p>2.5. Обезличивание персональных данных — действия, в результате которых невозможно определить без использования дополнительной информации принадлежность персональных данных конкретному Пользователю или иному субъекту персональных данных;</p>
      <p>2.6. Обработка персональных данных – любое действие (операция) или совокупность действий (операций), совершаемых с использованием средств автоматизации или без использования таких средств с персональными данными, включая сбор, запись, систематизацию, накопление, хранение, уточнение (обновление, изменение), извлечение, использование, передачу (распространение, предоставление, доступ), обезличивание, блокирование, удаление, уничтожение персональных данных;</p>
      <p>2.7. Оператор – государственный орган, муниципальный орган, юридическое или физическое лицо, самостоятельно или совместно с другими лицами организующие и (или) осуществляющие обработку персональных данных, а также определяющие цели обработки персональных данных, состав персональных данных, подлежащих обработке, действия (операции), совершаемые с персональными данными;</p>
      <p>2.8. Персональные данные – любая информация, относящаяся прямо или косвенно к определенному или определяемому Пользователю веб-сайта <a href="https://mentoring.digital/">https://mentoring.digital/</a>;</p>
      <p>2.9. Пользователь – любой посетитель веб-сайта <a href="https://mentoring.digital/">https://mentoring.digital/</a>;</p>
      <p>2.10. Предоставление персональных данных – действия, направленные на раскрытие персональных данных определенному лицу или определенному кругу лиц;</p>
      <p>2.11. Распространение персональных данных – любые действия, направленные на раскрытие персональных данных неопределенному кругу лиц (передача персональных данных) или на ознакомление с персональными данными неограниченного круга лиц, в том числе обнародование персональных данных в средствах массовой информации, размещение в информационно-телекоммуникационных сетях или предоставление доступа к персональным данным каким-либо иным способом;</p>
      <p>2.12. Трансграничная передача персональных данных – передача персональных данных на территорию иностранного государства органу власти иностранного государства, иностранному физическому или иностранному юридическому лицу;</p>
      <p>2.13. Уничтожение персональных данных – любые действия, в результате которых персональные данные уничтожаются безвозвратно с невозможностью дальнейшего восстановления содержания персональных данных в информационной системе персональных данных и (или) уничтожаются материальные носители персональных данных.</p>
      <p><b>3. Оператор может обрабатывать следующие персональные данные Пользователя</b></p>
      <p>3.1. Фамилия, имя, отчество;</p>
      <p>3.2. Электронный адрес;</p>
      <p>3.3. Фотографии;</p>
      <p>3.4. Сведения об образовании, профессии, специальности и квалификации, реквизиты документов об образовании;</p>
      <p>3.5. Сведения о занимаемых ранее должностях и стаже работы, воинской обязанности, воинском учете;</p>
      <p>3.6. Также на сайте происходит сбор и обработка обезличенных данных о посетителях (в т.ч. файлов «cookie») с помощью сервисов интернет-статистики (Яндекс Метрика и Гугл Аналитика и других).</p>
      <p>3.7. Вышеперечисленные данные далее по тексту Политики объединены общим понятием Персональные данные.</p>
      <p><b>4. Цели обработки персональных данных</b></p>
      <p>4.1. Цель обработки персональных данных Пользователя — информирование Пользователя посредством отправки электронных писем; предоставление доступа Пользователю к сервисам, информации и/или материалам, содержащимся на веб-сайте.</p>
      <p>4.2. Также Оператор имеет право направлять Пользователю уведомления о новых продуктах и услугах, специальных предложениях и различных событиях. Пользователь всегда может отказаться от получения информационных сообщений, направив Оператору письмо на адрес электронной почты d@mentoring.digital с пометкой «Отказ от уведомлений».</p>
      <p>4.3. Обезличенные данные Пользователей, собираемые с помощью сервисов интернет-статистики, служат для сбора информации о действиях Пользователей на сайте, улучшения качества сайта и его содержания.</p>
      <p><b>5. Правовые основания обработки персональных данных</b></p>
      <p>5.1. Оператор обрабатывает персональные данные Пользователя только в случае их заполнения и/или отправки Пользователем самостоятельно через специальные формы, расположенные на сайте <a href="https://mentoring.digital/">https://mentoring.digital/</a>. Заполняя соответствующие формы и/или отправляя свои персональные данные Оператору, Пользователь выражает свое согласие с данной Политикой.</p>
      <p>5.2. Оператор обрабатывает обезличенные данные о Пользователе в случае, если это разрешено в настройках браузера Пользователя (включено сохранение файлов «cookie» и использование технологии JavaScript).</p>
      <p><b>6. Порядок сбора, хранения, передачи и других видов обработки персональных данных</b></p>
      <p>Безопасность персональных данных, которые обрабатываются Оператором, обеспечивается путем реализации правовых, организационных и технических мер, необходимых для выполнения в полном объеме требований действующего законодательства в области защиты персональных данных.</p>
      <p>6.1. Оператор обеспечивает сохранность персональных данных и принимает все возможные меры, исключающие доступ к персональным данным неуполномоченных лиц.</p>
      <p>6.2. Персональные данные Пользователя никогда, ни при каких условиях не будут переданы третьим лицам, за исключением случаев, связанных с исполнением действующего законодательства.</p>
      <p>6.3. В случае выявления неточностей в персональных данных, Пользователь может актуализировать их самостоятельно, путем направления Оператору уведомление на адрес электронной почты Оператора d@mentoring.digital с пометкой «персональные данные».</p>
      <p>6.4. Срок обработки персональных данных является неограниченным. Пользователь может в любой момент отозвать свое согласие на обработку персональных данных, направив Оператору уведомление посредством электронной почты на электронный адрес Оператора d@mentoring.digital с пометкой «Отзыв персональных данных».</p>
      <p><b>7. Трансграничная передача персональных данных</b></p>
      <p>7.1. Оператор до начала осуществления трансграничной передачи персональных данных обязан убедиться в том, что иностранным государством, на территорию которого предполагается осуществлять передачу персональных данных, обеспечивается надежная защита прав субъектов персональных данных.</p>
      <p>7.2. Трансграничная передача персональных данных на территории иностранных государств, не отвечающих вышеуказанным требованиям, может осуществляться только в случае наличия согласия в письменной форме субъекта персональных данных на трансграничную передачу его персональных данных и/или исполнения договора, стороной которого является субъект персональных данных.</p>
      <p><b>8. Заключительные положения</b></p>
      <p>8.1. Пользователь может получить любые разъяснения по интересующим вопросам, касающимся обработки его персональных данных, обратившись к Оператору с помощью электронной почты d@mentoring.digital.</p>
      <p>8.2. В данном документе будут отражены любые изменения политики обработки персональных данных Оператором. Политика действует бессрочно до замены ее новой версией.</p>
      <p>8.3. Актуальная версия Политики в свободном доступе расположена в сети Интернет по адресу <a href="https://mentoring.digital/privacy/">https://mentoring.digital/privacy/</a>.</p>
    </div>
  </main>
</template>

<script setup>
useHead({
  title: 'Политика конфиденциальности'
})
</script>

<style scoped>
.page-title {
  margin-bottom: 16px;
}
ul {
  list-style: initial;
  padding-left: 20px;
}
li {
  list-style: initial;
}
p {
  margin: 5px 0;
}
</style>
